<template>
  <div class="public-page">
    <b-navbar toggleable="lg" type="light" variant="light" class="sticky-top">
      <b-row class="w-100 px-lg-3 m-0 header-public">
        <b-col sm="3">
					<b-link id="logo" href="https://zircly.com" data-supplied-ml-starting-dark="false" data-supplied-ml-starting="false" data-supplied-ml="false">
					  <img class="stnd skip-lazy default-logo dark-version mt-25" width="150" alt="Zircly" src="https://zircly.com/wp-content/uploads/2022/10/logo.png" srcset="https://zircly.com/wp-content/uploads/2022/10/logo.png 1x, https://zircly.com/wp-content/uploads/2022/10/logo.png 2x">
          </b-link>
        </b-col>
        <b-col sm="9" class="d-flex align-items-center justify-content-end header-button">
          <b-button variant="primary" pill href="https://login.zircly.com/register" target="_blank">Get Started</b-button>
          <b-button variant="outline-primary" pill href="https://zircly.com/schedule-a-demo/" target="_blank">Book A Demo</b-button>
        </b-col>
      </b-row>
      <!-- progress bar -->
      <div class="progress-bar-container">
        <div class="progress-bar"></div>
      </div>
    </b-navbar>
    <div class="container p-0">
      <b-row class="left-content mx-0">
        <b-col sm="12" lg="9" class="px-0 mx-0">
          <div class="card shadow-none bottom-body mb-50">
              <div class="card-body" v-if="postData">
                  <div class="post-card-header mb-1">
                    <h3 class="card-header pb-25 mt-lg-75 px-0 pt-lg-2">
                      {{ postData.title }}
                    </h3>
                    <div class="d-flex justify-content-start align-items-center">
                      <div class="profile-user-info d-flex align-items-center">
                        <h6 class="mb-0"><span class="font-semibold"></span> {{ postData.user.name }}</h6>
                        <span class="dot-icon">&#x2022;</span>
                        <span class="date">{{ postData.created_at | formatDate }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="post-body">
                      <div style="position: relative">
                          <div>
                            <b-card-text v-html="postData.body"></b-card-text> 
                          </div>
                      </div>
                  </div>
              </div>
              <div class="mx-auto p-md-0 p-sm-2" v-else>
                <div class="d-md-flex align-items-center justify-content-center empty-content">
                  <div class="left-text">
                    <h5>Oops, the content is revoked</h5>
                    <p>The author has revoked the content you're looking for.</p>
                  </div>
                  <div class="right-img">
                    <img src="../../assets/images/icons/publicurl/error-img.svg" width="200px">
                  </div>
                </div>
              </div>

              <div class="mx-md-auto mx-2" v-if="showErrorImage">
                <div class="d-md-flex align-items-center justify-content-center empty-content">
                  <div class="left-text">
                    <h5>Oops, the content is revoked</h5>
                    <p>The author has revoked the content you're looking for.</p>
                  </div>
                  <div class="right-img">
                    <img src="../../assets/images/icons/publicurl/error-img.svg" width="200px">
                  </div>
                </div>
              </div>

              <b-row  v-if="postData" class="mt-50 like-button top-cmd mx-2 public-cmd d-none" @click.stop>
                  <b-col cols="8" class="d-flex justify-content-start mb-0 pl-0">
                    <b-link class="d-flex align-items-center text-muted text-nowrap pr-1">
                      <div class="likes pl-0"  v-if="!postData.you_liked ">

                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none"
                          title="Like" class="active-like" @click="likePost(postData, index, 'like')">
                          <g>
                            <path
                              d="M5.83333 9.16671V15.8334C5.83333 16.0544 5.74554 16.2663 5.58926 16.4226C5.43298 16.5789 5.22101 16.6667 5 16.6667H3.33333C3.11232 16.6667 2.90036 16.5789 2.74408 16.4226C2.5878 16.2663 2.5 16.0544 2.5 15.8334V10C2.5 9.77903 2.5878 9.56707 2.74408 9.41079C2.90036 9.2545 3.11232 9.16671 3.33333 9.16671H5.83333ZM5.83333 9.16671C6.71739 9.16671 7.56523 8.81552 8.19036 8.1904C8.81548 7.56528 9.16667 6.71743 9.16667 5.83337V5.00004C9.16667 4.55801 9.34226 4.13409 9.65482 3.82153C9.96738 3.50897 10.3913 3.33337 10.8333 3.33337C11.2754 3.33337 11.6993 3.50897 12.0118 3.82153C12.3244 4.13409 12.5 4.55801 12.5 5.00004V9.16671H15C15.442 9.16671 15.866 9.3423 16.1785 9.65486C16.4911 9.96742 16.6667 10.3913 16.6667 10.8334L15.8333 15C15.7135 15.5113 15.4861 15.9502 15.1855 16.2508C14.8849 16.5514 14.5274 16.6974 14.1667 16.6667H8.33333C7.67029 16.6667 7.03441 16.4033 6.56557 15.9345C6.09673 15.4656 5.83333 14.8297 5.83333 14.1667"
                              stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath>
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <div class="emoji-reactions">
                          <div class="emoji-item" @click="likePost(postData, index, 'like')">
                            <b-img :src="require('@/assets/images/icons/like/like.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Like"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(postData, index, 'clap')">
                            <b-img :src="require('@/assets/images/icons/like/appreciate.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Appreciate"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(postData, index, 'love')">
                            <b-img :src="require('@/assets/images/icons/like/love.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Love"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(postData, index, 'celebrate')">
                            <b-img :src="require('@/assets/images/icons/like/celebrate.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(postData, index, 'idea')">
                            <b-img :src="require('@/assets/images/icons/like/insightful.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(postData, index, 'laugh')">
                            <b-img :src="require('@/assets/images/icons/like/laugh.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Laugh"></b-img>
                          </div>
                        </div>
                      </div>
                      <div class="likes" v-if="postData.you_liked && postData.like_type">
                        <div v-if="postData.like_type == 'like'" @click="likePost(postData, index, 'like')">
                          <b-img :src="require('@/assets/images/icons/like/like.svg')" width="24" title="Like"
                            class="active-like"></b-img>
                        </div>
                        <div v-if="postData.like_type == 'clap'" @click="likePost(postData, index, 'clap')">
                          <b-img :src="require('@/assets/images/icons/like/appreciate.svg')
                            " width="24" title="appreciate" class=""></b-img>
                        </div>
                        <div v-if="postData.like_type == 'love'" @click="likePost(postData, index, 'love')">
                          <b-img :src="require('@/assets/images/icons/like/love.svg')
                            " width="24" title="Love" class=""></b-img>
                        </div>
                        <div v-if="postData.like_type == 'celebrate'" @click="likePost(postData, index, 'celebrate')">
                          <b-img :src="require('@/assets/images/icons/like/celebrate.svg')
                            " width="24" title="Celebrate" class=""></b-img>
                        </div>
                        <div v-if="postData.like_type == 'idea'" @click="likePost(postData, index, 'idea')">
                          <b-img :src="require('@/assets/images/icons/like/insightful.svg')
                            " width="24" title="Insightful" class="active-idea"></b-img>
                        </div>
                        <div v-if="postData.like_type == 'laugh'" @click="likePost(postData, index, 'laugh')">
                          <b-img :src="require('@/assets/images/icons/like/laugh.svg')" width="24" title="Laugh"
                            class=""></b-img>
                        </div>

                        <div class="emoji-reactions">
                          <div class="emoji-item" @click="likePost(postData, index, 'like')">
                            <b-img :src="require('@/assets/images/icons/like/like.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Like"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(postData, index, 'clap')">
                            <b-img :src="require('@/assets/images/icons/like/appreciate.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Appreciate"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(postData, index, 'love')">
                            <b-img :src="require('@/assets/images/icons/like/love.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Love"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(postData, index, 'celebrate')">
                            <b-img :src="require('@/assets/images/icons/like/celebrate.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(postData, index, 'idea')">
                            <b-img :src="require('@/assets/images/icons/like/insightful.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(postData, index, 'laugh')">
                            <b-img :src="require('@/assets/images/icons/like/laugh.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Laugh"></b-img>
                          </div>
                        </div>
                      </div>
                      <span class="text-primary ml-25" v-if="postData.like_count"
                                  >{{
                                    postData.like_count ? postData.like_count : 0}}
                                   </span>
                    </b-link>
                    <b-link v-b-toggle.add-sidebar-view class="text-body text-nowrap d-flex align-items-center" v-if="postData.comment_count">
                      <feather-icon icon="MessageCircleIcon" size="20" class="profile-icon mr-50 text-secondary" v-b-tooltip.hover.v-default title="Comments" />
                        <span class="text-muted"> {{ postData.comment_count ? postData.comment_count: 0 }}</span>
                    </b-link>
                  </b-col>
                  <!-- <b-modal :id="'show-likes-' + postData.hashid" hide-footer modal-class="modal-primary modal-likes"
                    scrollable title="Reactions" class="text-danger" @show="getPostReactions(postData.hashid)">
                    <b-list-group v-if="postLikes && postLikes.length">
                      <b-list-group-item v-for="like in postLikes" :key="'user-' + like.user.hashid"
                        class="d-flex align-items-center">
                        <b-avatar :src="like.user.avatar" size="24" class="mr-1" />
                        {{ like.user.name }}
                        <div class="ml-auto" v-if="like.type == 'like'">
                          <b-img :src="require('@/assets/images/icons/like/like.svg')" width="24" title="Like"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'clap'">
                          <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                            " width="24" title="Celebrate"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'love'">
                          <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                            " width="24" title="Love"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'support'">
                          <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                            " width="24" title="Support"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'idea'">
                          <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                            " width="24" title="Insightful"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'smile'">
                          <b-img :src="require('@/assets/images/icons/like/smile.svg')" width="24" title="Funny"></b-img>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                    <b-card-text v-else-if="postLikes && postLikes.length == 0">
                      <div class="item error text-center my-2 empty-data">
                        <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                          style="background-color: transparent" />
                        <h4> OH NO!</h4>
                        <p class="d-block mb-1">No Records Found!</p>
                      </div>
                    </b-card-text>
                    <b-card-text v-else-if="postLikes == null">
                      <div class="d-flex align-items-center">
                        <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                          style="background-color: transparent" />
                        <h4> Loading </h4>
                      </div>
                    </b-card-text>
                  </b-modal> -->
                  <!-- <b-col cols="4" class="d-flex justify-content-end align-items-center mb-0 right-icons">
                    <b-link v-b-toggle.add-sidebar-view @click="viewPost(data)"
                      class="text-body text-nowrap d-flex align-items-center">
                      <feather-icon icon="MessageCircleIcon" size="20" class="profile-icon mr-25 text-primary"
                        v-b-tooltip.hover.v-default title="Comments" />
                      <span v-if="data.comments_count" class="text-muted">{{
                        kFormatter(data.comments_count)
                      }}</span>
                    </b-link>
                    <b-link v-if="data.visit_count_total" class="text-body text-nowrap d-flex align-items-center mx-2"
                      @click="showVisits(data.hashid)">
                      <feather-icon icon="EyeIcon" size="20" class="profile-icon mr-25 text-primary"
                        v-b-tooltip.hover.v-default title="Views" />
                      <span class="text-muted">{{
                        kFormatter(data.visit_count_total)
                      }}</span>
                    </b-link>

                    <b-modal :id="'show-visits-' + data.hashid" hide-footer modal-class="modal-primary show-visits" scrollable
                      title="Views" class="text-danger">
                      <b-list-group v-if="postViews && postViews.length">
                        <b-list-group-item v-for="user in postViews" :key="'user-' + user.hashid"
                          class="d-flex align-items-center">
                          <b-avatar :src="user.avatar" size="24" class="mr-75"/>
                          {{ user.name }}
                        </b-list-group-item>
                      </b-list-group>
                      <b-card-text v-else-if="isBusy">
                        <div class="d-flex align-items-center">
                          <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                            style="background-color: transparent" />
                          <h4> Loading </h4>
                        </div>
                      </b-card-text>
                      <b-card-text v-else>
                        <div class="item error text-center my-2 empty-data">
                          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                            style="background-color: transparent" />
                          <p class="d-block mb-1">No Records Found!</p>
                        </div>
                      </b-card-text>
                    </b-modal>
                    <b-link class="text-body text-nowrap d-flex align-items-center" @click="savePost(data, index)">
                      <feather-icon icon="BookmarkIcon" class="mr-25 text-primary"
                        :class="data.youSaved ? 'saved-post' : 'profile-icon'" v-b-tooltip.hover.v-default
                        :title="data.youSaved ? 'Remove from Saved' : 'Save Post'" size="20" />
                      
                    </b-link>
                  </b-col> -->
                  <b-col cols="4" class="d-flex align-items-center justify-content-end share-drop pr-0">
                    <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none"
                      no-caret>
                      <template #button-content>
                        <div class="d-flex align-items-center justify-content-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clip-path="url(#clip0_7899_40972)">
                              <path d="M10.8335 3.33398V6.66732C5.35432 7.52398 3.31682 12.324 2.50016 16.6673C2.46932 16.839 6.98682 11.699 10.8335 11.6673V15.0007L17.5002 9.16732L10.8335 3.33398Z" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_7899_40972">
                                <rect width="20" height="20" fill="white"/>
                              </clipPath>
                            </defs>
                          </svg>
                          <span class="share-text">Share</span>
                        </div>
                      </template>
                      <b-dropdown-item class="btn-sm p-0 text-muted" @click="shareUrl()">
                        <feather-icon class="cursor-pointer mr-50" icon="CopyIcon" size="16"/>
                          <span class="">Copy Link</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
      
        <div v-if="postData" class="w-100 cmt-posts d-flex align-items-end justify-content-center">
            <div class="w-100 border p-751 d-flex mb-0 commentBox mr-75 ml-2 my-2 main-post">
              <CommentBox placeholder="Share your thoughts..." className="comment-box" :mentionList="[]"
                :toolbar="false" width="80%" 
                :id="'comment-field-' + index"
                />
            </div>
            <b-button variant="primary"   @click="addComments(postData,index)"
              class="blog-btn-block mr-1 search-post" :disabled="commentSubmitClicked">
              Comment
          </b-button>
          </div>
          </div>
        
      <div v-for="(comment, index) in comments" :key="'comment-' + index" class="reply-post mb-1 mt-50 px-2">
        <!-- Parent Comment -->
        <div class="cmd-main">
          <!-- Parent Comment Header -->
          <div class="d-flex align-items-start w-100 over-cmd px-2 pt-1 pb-0">
            <div class="profile-user-info w-100 right-cmt mt-0">
              <div class="d-flex align-items-start justify-content-between">
                <div class="profile-user-info">
                  <h6 class="mb-0">{{ comment.username }}</h6>
                </div>
                <span class="small">{{ comment.created_at | formatDate }}</span>
              </div>
              <p class="mb-0 mt-75" v-html="comment.comment"></p>
              <!-- </div> -->
            </div>

          </div>
          <div class="option-reply d-flex align-items-center justify-content-between w-100 pl-3" style="padding: 7px 10px !important;">
            <div class="cmtnest-reply pl-25" @click="viewReply(index)">
              <p class="nav-link reply-count cursor-pointer mb-0">
                <!-- <feather-icon icon="PlusIcon" size="14" v-if="openReplyIndex !== index && comment.reply_count " /> -->
                {{ openReplyIndex === index ? 'Hide Replies' : (comment.reply_count ? `${comment.reply_count} Replies` : '') }} </p>
            </div>
            <div class="row like-button mx-0">
                  <div class="action-group d-flex align-items-center my-25">
                    <b-link class="d-flex align-items-center text-secondary text-nowrap" @click="addReply(comment,index)">
                      Reply
                    </b-link>
                  </div>
                </div>
            </div>
          <!-- Child Comments (NestedComments Component) -->
          <!-- <NestedComments v-if="comment.replies && comment.replies.length > 0" :comments="comment.replies" /> -->
          <!-- Indent Child Comments -->
        </div>
        <div class="w-100 cmt-posts d-flex align-items-end justify-content-center" v-if="replyIndex === index && comment_id !== null">
              <div  class="border w-100 p-751 d-flex mb-0 flex-row align-items-end justify-content-around commentBox round mr-75">
                <CommentBox placeholder="Enter Your Reply" className="w-100 comment-box" :mentionList="[]"
                            :id="'comment-field-' + comment_id" :toolbar="false" />
              </div>
              <b-button variant="primary" @click="addComments(postData, index)" pill
                        class="blog-btn-block rounded-circle p-75 search-post mb-0" :disabled="commentSubmitClicked">
                <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="22"></b-img>
              </b-button>
              <div class="replyto-cmd pb-1 my-1" >
                <b-link class="text-primary btn btn-icon rounded-circle p-50" @click="closeReplyBox">
                  <feather-icon icon="XIcon" size="18" v-b-tooltip.hover.v-default title="Cancel" />
                </b-link>
              </div>
            </div>
        <div v-if=" openReplyIndex === index && comment.replies && comment.replies.length > 0" class="cmtnest-reply my-1">
            <NestedComments :comments="comment.replies" :postData="postData" />
          </div>
        </div>
        <!-- More Comments-->
        <div v-if="postData" class="more-cmt p-2 cursor-pointer mb-2" @click="loadMoreComments(postData)">
          <span class="text-underline more-text">More comments</span>
        </div>
        </b-col>
        <b-col sm="12" lg="3" class="right-promotion pr-md-0">
        <div class="signup-pop">
          <div class="px-2 pt-2">
            <h3>Try Zircly For FREE</h3>
            <p>Your Community, Your Rules. Elevate, Engagement, Collaboration, and Growth.</p>
            <b-link variant="primary" class="try-btn btn-primary button" href="https://login.zircly.com/register" target="_blank"><span>Try Now</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_7899_40829)">
                  <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 16L19 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 8L19 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_7899_40829">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </b-link>
          </div>
          <div class="w-100 placeholder-img d-flex align-items-center justify-content-end">
            <b-img :src="require('@/assets/images/icons/publicurl/zircly-placeholder.svg')" width="132" />
          </div>
        </div>
      </b-col>
  </b-row>
        <!-- Bottom Zircly Information -->
        <!-- <div class="blog-session px-lg-5 mx-1">
        <h3 class="blog-header mb-2">Checkout Latest Zircly Blogs</h3>
        <b-row class="blog-scroll">
          <b-col sm="12" md="4">
            <div class="inner-wrap">
              <div class="post-content">
                <b-link class="entire-meta-link" href="https://zircly.com/blog/finding-your-tribe-why-coaches-and-course-creators-thrive-in-communities/" aria-label="Finding Your Tribe: Why Coaches and Course Creators Thrive in Communities"></b-link>
                <span class="post-featured-img mb-1 d-block">
                  <div class="date-blog">
                    <h6 class="mb-0">23</h6>
                    <h5 class="mb-0">May</h5>
                  </div>
                  <img width="100%" src="https://zircly.com/wp-content/uploads/2024/03/Zircly-SNS-Cover-16.png" class="" alt="blog-image">
                </span>
                <span class="meta-category mb-25">
                  <b-link class="coaching" href="https://zircly.com/blog/category/coaching/">Coaching</b-link>
                </span>
                <div class="article-content-wrap">
                  <div class="post-header">
                    <h3 class="title">
                      <b-link href="https://zircly.com/blog/finding-your-tribe-why-coaches-and-course-creators-thrive-in-communities/">Finding Your Tribe: Why Coaches and Course Creators Thrive in Communities</b-link>
                    </h3>
                  </div>
                  <p class="excerpt">With the growth of the coaching and course creation industry also comes the growth of…</p>
                  <div class="user-details">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-avatar style="background: transparent" size="48" class="mr-1" />
                      <div class="profile-user-info">
                        <h6 class="mb-0">Leeanna Alvord</h6>
                        <small class="text-muted"> Software Developer</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="4">
            <div class="inner-wrap">
              <div class="post-content">
                <b-link class="entire-meta-link" href="https://zircly.com/blog/finding-your-tribe-why-coaches-and-course-creators-thrive-in-communities/" aria-label="Finding Your Tribe: Why Coaches and Course Creators Thrive in Communities"></b-link>
                <span class="post-featured-img mb-1 d-block">
                  <div class="date-blog">
                    <h6 class="mb-0">03</h6>
                    <h5 class="mb-0">July</h5>
                  </div>
                  <img width="100%" src="https://zircly.com/wp-content/uploads/2024/03/Zircly-SNS-Cover-16.png" class="" alt="blog-image">
                </span>
                <span class="meta-category mb-25">
                  <b-link class="coaching" href="https://zircly.com/blog/category/coaching/">Coaching</b-link>
                </span>
                <div class="article-content-wrap">
                  <div class="post-header">
                    <h3 class="title">
                      <b-link href="https://zircly.com/blog/finding-your-tribe-why-coaches-and-course-creators-thrive-in-communities/">Finding Your Tribe: Why Coaches and Course Creators Thrive in Communities</b-link>
                    </h3>
                  </div>
                  <p class="excerpt">With the growth of the coaching and course creation industry also comes the growth of…</p>
                  <div class="user-details">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-avatar style="background: transparent" size="48" class="mr-1" />
                      <div class="profile-user-info">
                        <h6 class="mb-0">Leeanna Alvord</h6>
                        <small class="text-muted"> Software Developer</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="4">
            <div class="inner-wrap">
              <div class="post-content">
                <b-link class="entire-meta-link" href="https://zircly.com/blog/finding-your-tribe-why-coaches-and-course-creators-thrive-in-communities/" aria-label="Finding Your Tribe: Why Coaches and Course Creators Thrive in Communities"></b-link>
                <span class="post-featured-img mb-1 d-block">
                  <div class="date-blog">
                    <h6 class="mb-0">13</h6>
                    <h5 class="mb-0">March</h5>
                  </div>
                  <img width="100%" src="https://zircly.com/wp-content/uploads/2024/03/Zircly-SNS-Cover-16.png" class="" alt="blog-image">
                </span>
                <span class="meta-category mb-25">
                  <b-link class="coaching" href="https://zircly.com/blog/category/coaching/">Coaching</b-link>
                </span>
                <div class="article-content-wrap">
                  <div class="post-header">
                    <h3 class="title">
                      <b-link href="https://zircly.com/blog/finding-your-tribe-why-coaches-and-course-creators-thrive-in-communities/">Finding Your Tribe: Why Coaches and Course Creators Thrive in Communities</b-link>
                    </h3>
                  </div>
                  <p class="excerpt">With the growth of the coaching and course creation industry also comes the growth of…</p>
                  <div class="user-details">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-avatar style="background: transparent" size="48" class="mr-1" />
                      <div class="profile-user-info">
                        <h6 class="mb-0">Leeanna Alvord</h6>
                        <small class="text-muted"> Software Developer</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="growth-middle text-center my-5">
          <div class="inner-middle">
            <h2 style="color: #0f172a;text-align: center" class="vc_custom_heading">Ignite Engagement, Foster Collaboration, and Fuel Explosive Growth.</h2>
            <p class="pb-2 pt-50">Sign Up Now and Lead the Revolution!</p>
            <div class="d-flex align-items-center justify-content-center middle-btn">
              <b-link class="register-btn" href="https://login.zircly.com/register" target="_blank">Get Started</b-link>
              <b-link class="demo-btn" href="https://zircly.com/schedule-a-demo/" target="_blank">Book A Demo</b-link>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <b-button v-b-modal.page-loadpop>click page load promo design</b-button> -->
      <b-modal id="page-loadpop" :hide-header="true" :hide-footer="true" centered>
        <div class="signup-pop">
          <feather-icon class="cursor-pointer promo-close" icon="XIcon" size="20" @click="confirmCloseButton"/>
          <div class="px-2 pt-2">
            <h3>Try Zircly For FREE</h3>
            <p>Your Community, Your Rules. Elevate, Engagement, Collaboration, and Growth.</p>
            <b-button variant="primary" class="try-btn"><span>Try Now</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_7899_40829)">
                  <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 16L19 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 8L19 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_7899_40829">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </b-button>
          </div>
          <div class="w-100 placeholder-img d-flex align-items-center justify-content-end">
            <b-img :src="require('@/assets/images/icons/publicurl/zircly-placeholder.svg')" width="132" />
          </div>
        </div>
      </b-modal>
</div>
<div id="footer">
      <div class="container">
        <b-row>
          <b-col sm="12" md="7">
            <div class="nectar-split-heading markup-generated animated-in">
              <h2 class="mb-2">
                <span>Build</span> <span>A</span> <span>Crucible</span> <span>of</span> <span>Culture</span>
              </h2>
              <b-link class="mt-1 try-btn" href="https://login.zircly.com/register" target="_blank">TRY FOR FREE</b-link>
            </div>
          </b-col>
          <b-col sm="12" md="5" class="d-flex align-items-center justify-content-md-end right-menu mt-sm-3 mt-md-0">
              <ul class="p-0 m-0">
                <li class="list-unstyled mb-1">
                  <h5>Contact Us</h5>
                </li>
                <li class="list-unstyled mb-25 pb-1">
                  <b-link class="link_text" href="mailto:info@zircly.com">info@zircly.com</b-link>
                </li>
                <li class="list-unstyled mb-25 pb-1">
                  <b-link class="link_text" href="https://zircly.com/privacy-policy/" target="_blank">Privacy Policy</b-link>
                </li>
                <li class="list-unstyled mb-25 pb-1">
                  <b-link class="link_text" href="https://zircly.com/terms-of-use/" target="_blank">Terms of Use</b-link>
                </li>
              </ul>
          </b-col>
        </b-row>
        <div class="d-flex align-items-center justify-content-md-end copy-right">
          <p class="py-1">© Copyright <span class="nectar-current-year">2024</span> Zircly. All rights reserved, DCKAP.</p>
        </div>
      </div>
    </div>
    <div id="myModal" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Subscribe our Newsletter</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
				<p>Subscribe to our mailing list to get the latest updates straight in your inbox.</p>
                <form>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Name">
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" placeholder="Email Address">
                    </div>
                    <button type="submit" class="btn btn-primary">Subscribe</button>
                </form>
            </div>
        </div>
    </div>
</div>
<b-modal
    id="modal-login"
    cancel-variant="outline-secondary"
    centered
    title="Enter Your Names"
    v-model="showUsernameModal"
    hide-footer
>
    <b-form>
        <b-form-group>
            <b-form-input
                id="username"
                type="text"
                v-model="userName"
            ></b-form-input>
        </b-form-group>
    </b-form>

    <div class="d-flex justify-content-end">
        <!-- Your custom buttons here -->
        <b-button class="mr-1" variant="outline-secondary" @click="showUsernameModal = false" pill>Cancel</b-button>
        <b-button variant="primary" @click="submitUsername(postData,index)" pill>Continue</b-button>
    </div>
</b-modal>


</div>
</template>

<script>
import {
  BModal,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BImg,
  BEmbed,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import { kFormatter } from "@core/utils/filter";
import { bus } from "@/main.js";
import axios from "@axios";
import $ from "jquery";
// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import Replies from "./Replies.vue";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
import NestedComments from './NestedComments.vue';
import Comments from "./Comments.vue";


Vue.use(VueCookies);

Vue.filter('formatDate', function(value) {
  if (value) {
    const date = new Date(value);
    return date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }
});
import QuillInput from "@/components/QuillInput.vue";
import CommentBox from "@/components/CommentBox.vue";
import CryptoJS from 'crypto-js';
// import VueMeta from 'vue-meta';

//Vue.use(VueMeta);
// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BImg,
    BEmbed,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BModal,
    vSelect,
    Comments,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    Replies,
    QuillInput,
    CommentBox,
    NestedComments

  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

  },
  metaInfo() {
    // const imageUrl = this.postData.find(item => item.type === 'image')?.url || '';
    return {
      title: this.postData?.title,
      meta: [
        { property: 'og:title', content: this.postData?.title },
        { property: 'og:image', content: 'https://example.com/default-image.jpg' },
        { property: 'og:body', content: this.postData?.body  },
      ],
    };
  },
  data() {
    return {
      showCommentsCount: 3,
      openReplyIndex: null,
      postData: null,
      index:0,
      replyIndex: null,
      showErrorImage: false,
      showUsernameModal: false,
      showMoreButton: false,
      userName: '',
      comments:[],
      likes:[],
      isBusy: true,
      data_local: {
        channel_type: "community",
      },
      show: false,
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
      channel_types: [],
      channel_type_select: [],
      post_data: {
        user: {},
        attachments: {},
      },
      comment_id: null,
      reply_to: null,
      userData: this.$cookies.get("userData"),
      editComments: [],
      postViews: [],
      commentSubmitClicked:false,
      hashPostTypes: [
        "####photo-post####",
        "####video-post####",
        "####quote-post####",
      ],
    };
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-tickets";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  created() {},

  mounted() {
    if (this.$route.query.post_hash_id) {
      this.itemId = this.$route.query.post_hash_id;
      this.show = true;
    }

    this.getPost()
    this.userName = localStorage.getItem('username') !== "null" ? localStorage.getItem('username') : '';
    // Top Progress bar
    function updateProgressBar() {
      const progressBar = document.querySelector('.progress-bar');
      const totalHeight = document.body.scrollHeight - window.innerHeight;
      const progress = (window.pageYOffset / totalHeight) * 100;
      progressBar.style.width = progress + '%';
    }
    updateProgressBar(); 
    window.addEventListener('scroll', updateProgressBar);
    window.addEventListener('resize', updateProgressBar);
  },

  methods: {
    confirmCloseButton(){

    },
    viewReply(index) {
    if (this.openReplyIndex === index) {
      this.openReplyIndex = null; // Close the replies if they are already open
    } else {
      this.openReplyIndex = index; // Open the replies of the clicked comment
    }
  },
    closeReplyBox(){
    
       this.comment_id = null
    
    },
    // getReplyComments(comment_id,liked = false) {
    //   const self = this;
    //   this.isBusy = true;
    //   let data = {};

    //   data.url = "/comments/" + comment_id + "/replies";
    //   self.$store
    //     .dispatch("app/get", data)
    //     .then((res) => {
    //       this.replies = res.data.data;
    //       this.isBusy = false;
    //       if(!liked){
    //         this.show = !this.show;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       self.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Oops! Custom Fields Getting Failed",
    //           icon: "BellIcon",
    //           variant: "danger",
    //         },
    //       });
    //     });
    // },
    loadMoreComments(post) {
    axios.get(`/comments/get_public_comments/${post.hashid}?allComments=true`)
    .then(response => {
      console.log(response.data.data)
        this.comments = response.data.data;
    })
    .catch(error => {
        console.error('Error loading more comments:', error);
    });
},

    getPost(){
       
      let post_id = window?.location.href.split("/")[4]

      let userId = this.generateDeviceID()
      axios.get(`/posts/get_public_post/${post_id}?user_id=${userId}`,{
          params: {
            user_id: userId
          },
        }).then((res) => {
      this.postData =  res.data.data;
      this.getComments(this.postData);
      this.getLikes(this.postData);
      // Create Open Graph meta tags
      const ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      ogTitle.setAttribute('content', this.postData.title);

      const ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      ogDescription.setAttribute('content', 'Open in Zircly to View the Full Post');

      const ogUrl = document.createElement('meta');
      ogUrl.setAttribute('property', 'og:url');
      ogUrl.setAttribute('content', window.location.href);

      const ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      ogType.setAttribute('content', 'website');

      // Append the Open Graph meta tags to the head
      document.head.appendChild(ogTitle);
      document.head.appendChild(ogDescription);
      document.head.appendChild(ogUrl);
      document.head.appendChild(ogType);
      if(this.postData.attachments && this.postData.attachments.length > 0){
        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.setAttribute('content', this.postData.attachments[0].link);
        document.head.appendChild(ogImage);
      }  

  })
  .catch((err) => {
    console.log(err);
    if (err.response && err.response.status && err.response.status == 409) {
      this.showErrorImage = true; 

      console.log(this.showErrorImage)
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: err.response.data.error,
      //     icon: 'BellIcon',
      //     variant: 'danger',
      //     text: err.toString(),
      //   },
      // });
    }
  }); 
    },



    generateUniqueBrowserID() {
      const userAgent = navigator.userAgent;
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const language = navigator.language;
      const uniqueID = userAgent + screenWidth + screenHeight + language+ this.generateUUID();
      return uniqueID;
    },

    generateDeviceID() {
      var id = this.$cookies.get("ZirclyPublicDeviceID");
      if(!id){
        const uniqueID = this.generateUniqueBrowserID();
        const hashedID = CryptoJS.SHA256(uniqueID).toString(CryptoJS.enc.Hex);
        this.$cookies.set("ZirclyPublicDeviceID",hashedID);
        return hashedID;
      }
      return id;
    },

    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0,
              v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
    },

   addReply(comment,index) {
    this.comment_id = comment.hashid;
      self.comment_post_index = index;
    this.replyIndex = index;
 },
 getLikes(post){
  const deviceId = this.generateDeviceID()
  axios.get(`/posts/${post.hashid}/public_likes`, {
          params: {
            device_id: deviceId
          },
        }).then((res) => {
    // console.log(res)
     this.likes = res.data.data
 })
 .catch((err) => {
    if (err.response && err.response.status && err.response.status == 404) { 
      this.$toast({
        component: ToastificationContent,
        props: {
          title: err.response.data.error,
          icon: 'BellIcon',
          variant: 'danger',
          text: err.toString(),
        },
      });
    }
  }); 
},
shareUrl(){
  const copyText = `${window.location.href}`;
        navigator.clipboard.writeText(copyText); // Copy text to clipboard
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'BellIcon',
            variant: 'success',
            text: 'link copied to clipboard!',
          },
        });
    
},
  getComments(post){
      axios.get(`/comments/get_public_comments/${post.hashid}`)
      .then((res) => {
      this.comments =  res.data.data;
  })
  .catch((err) => {
    if (err.response && err.response.status && err.response.status == 404) {
      this.showErrorImage = true; 
      this.$toast({
        component: ToastificationContent,
        props: {
          title: err.response.data.error,
          icon: 'BellIcon',
          variant: 'danger',
          text: err.toString(),
        },
      });
    }
  }); 
    },

    likePost(data, index, type) {
      const deviceId = this.generateDeviceID()

      if (data.device_id && data.like_type == type) {
        this.$http.delete(`/posts/${data.hashid}/public_like/${deviceId}`).then((res) => {
           this.getPost();
        });
      } 
      else {
        let payload = {
          device_id : deviceId
        }
        this.$http.post(`/posts/${data.hashid}/public_like/${type}`,payload).then((res) => {
          this.getPost()
        });
      }
    },

    submitUsername(data,index) {
      localStorage.setItem('username', this.userName);
        this.showUsernameModal = false;
        this.addComments(data,index)
    },
    addComments(data,index){
      const self = this;
      var comment = document.getElementById(`comment-field-${index}`).value;
      if (self.comment_id) {
        var comment = document.getElementById(
          `comment-field-${self.comment_id}`
        ).value;
      }
      if (comment === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Can't post empty comment",
            variant: "danger",
          },
        });
        return false;
      }
      if(localStorage.getItem('username') == null){
        this.showUsernameModal = true;
      }
     else{

      let payload = {
        username: localStorage.getItem('username'),
        comment : comment,
        comment_id: self.comment_id,
      }
      axios.post(`/comments/${data.hashid}/public_comments`,payload)
      .then((res) => {
        this.comment_id = null
        document.getElementById(`comment-field-${index}`).value = "";
        $(".ql-editor").html("");
        this.getComments(this.postData)
        this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment Added Sucessfully",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "success",
                    },
                  });
                  this.content = "";

  })
      .catch((err) => {
        if (err.response && err.response.status && err.response.status == 404) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.error,
              icon: 'BellIcon',
              variant: 'danger',
              text: err.toString(),
            },
          });
        }
      }); 
        }
  }
  },
}
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

.public-page {
  background: #FFFFFF;
  .progress-bar-container {
  position: fixed;
  top: 71px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #f0f0f0;
  z-index: 9999;
}
.search-post {
  display: flex;
  position: absolute;
  left: auto;
  right: 10px;
  bottom: 18px;
  width: auto;
  min-width: 85px;
}
.try-btn {
  padding: 8px 18px;
  gap: 4px;
  border-radius: 35px;
  color: #fff;
  background:#38B22D;
  min-width: 100px;
  width: auto;
}
a {
  text-decoration: none;
}
.progress-bar {
  height: 100%;
  transition: all 0.3s ease;
}
  .header-button {
    gap: 24px;
  }
  .container {
    max-width: 100%;
    h3.blog-header {
      color: #000;
      font-size: 28px;
      font-weight: 600;
      line-height: 42px;
    }
    .inner-wrap {
      border-radius: 12px;
      border: 1px solid var(--Primary-95, #E5EFFF);
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 18px;
      .meta-category {
        display: block;
        a {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      h3 a {
        color:#333;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p.excerpt {
        color: #666;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
      }
      .profile-user-info {
        h6 {
          color: #333;
          font-size: 14px;
          font-weight: 500;
        }
      }
      small {
        color: #666;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
    .left-content {
      @media(min-width: 992px) {
        padding-left: 50px;
      }
    }
    .growth-middle {
      position: relative;
      border-radius: 28px;
      border: 1px solid#E5EFFF;
      &::after {
        background-image: url('../../assets/images/icons/publicurl/groeth-bg.svg');
        content: "";
        width: 100%;
        display: inline-block;
        background-position: center;
        background-size: 100%;
        height: 280px;
        z-index: -1;
        background-repeat: no-repeat;
      }
      .inner-middle {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 65%;
        h2 {
          color: #0F172A;
          font-size: 28px;
          font-weight: 600;
          line-height: 54px;
          letter-spacing: -0.216px;
          @media(max-width: 991px) {
            font-size: 20px;
          }
        }
        p {
          color: #666475;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          @media(max-width: 991px) {
            font-size: 14px;
          }
        }
        .middle-btn {
          gap: 24px;
          .register-btn {
            color:#F8FAFC;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.28px;
            border-radius: 41px;
            background:#38B22D;
            display: flex;
            padding: 12px 18px;
            justify-content: center;
            align-items: center;
            gap: 6px;
          }
          .demo-btn {
            display: flex;
            padding: 12px 18px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            border-radius: 44px;
            border: 1px solid#38B22D;
            color:#38B22D;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.28px;
          }
        }
      }
    }
  }
  #footer {
    padding: 80px 30px 38px 30px;
    background: #000;
    h2 {
    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
    color: #FFF;
    font-size: 60px;
    font-weight: 700;
    line-height: 70.08px;
    padding: 0;
  }
  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }
  span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
  }
  span:nth-child(1) {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  span:nth-child(2) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  span:nth-child(3) {
    animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  span:nth-child(4) {
    animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  span:nth-child(5) {
    animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  @keyframes fade-in {
      100% {
        opacity: 1;
        filter: blur(0);
      }
  }
  .right-menu {
    li {
      h5 {
        color: #FFF;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.48px;
      }
      a {
        color: #FFF;
        font-size: 20px;
        font-weight: 600;
        line-height: 28.08px;
        text-decoration-line: underline;
      }
    }
  }
  .copy-right {
    color: #FFF;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.32px;
  }
  }
  .signup-pop {
    border-radius: 15px 0px 0px 15px;
    background:#E8F9E7;
    position: sticky;
    top: 10rem;
    overflow-y: auto;
    width: 296px;
    height: max-content;
    button {
      display: flex;
      padding: 8px 18px;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }
    .placeholder-img {
      
    }
  }
  .right-promotion {
    display: flex;
    justify-content: end;
  }
  .cmt-posts .search-post {
	 margin-bottom: 10px;
	 padding: 6px 12px;
	 border-radius: 20px;
	 box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	 font-size: 12px;
	 font-weight: 500;
	 line-height: 18px;
	 align-items: center;
	 display: flex;
	 justify-content: center;
}
 .cmt-posts .new-comment-box .ql-formats, .cmt-posts .new-comment-box .ql-snow.ql-toolbar {
	 display: flex !important;
	 align-items: center;
}
 .cmt-posts .commentBox.main-post .search-post {
	 display: none;
}
 .cmt-posts .commentBox.main-post .ql-snow.ql-toolbar {
	 display: none !important;
}
 .cmt-posts .commentBox.main-post:focus .ql-snow.ql-toolbar, .cmt-posts .commentBox.main-post:focus-within .ql-snow.ql-toolbar, .cmt-posts .commentBox.main-post:focus-visible .ql-snow.ql-toolbar {
	 display: flex !important;
}
 .cmt-posts .commentBox.main-post:focus .search-post, .cmt-posts .commentBox.main-post:focus-within .search-post, .cmt-posts .commentBox.main-post:focus-visible .search-post {
	 display: flex;
	 width: auto;
	 min-width: 85px;
}
}
</style>
<style lang="scss">