<template>
    <div>
        <div
            v-for="(comment, index) in comments"
            :key="'comment-' + index"
            class="nest-reply my-75"
        >
            <div class="nested-main">
                <!-- Child Comment Header -->
                <div class="d-flex align-items-start w-100 over-cmd px-2 pt-2">
                    <div class="profile-user-info w-100 right-cmt">
                        <div class="profile-user-info d-flex align-items-center">
                            <h6 class="mb-0">{{ comment.username }}</h6>
                            <span class="dot mx-25"></span>
                            <small>{{ comment.created_at | formatDate }}</small>
                        </div>
                        <div class="mt-75 blog-description">
                            <p
                                class="child-comment-content mb-0"
                                v-html="comment.comment"
                            ></p>
                        </div>
                    </div>
                </div>
                <!-- Child Comment Content -->
                <div
                    class="option-reply nested-replycount d-flex align-items-center justify-content-between w-100 pl-3"
                >
                    <div class="cmtnest-reply" @click="viewReply(index)">
                        <p class="nav-link reply-count cursor-pointer mb-0">
                            <!-- <feather-icon
                                icon="PlusIcon"
                                size="14"
                                v-if="
                                    openReplyIndex !== index &&
                                    comment.reply_count
                                "
                            /> -->
                            {{
                                openReplyIndex === index
                                    ? "Hide Replies"
                                    : comment.reply_count
                                    ? `${comment.reply_count} Replies`
                                    : ""
                            }}
                        </p>
                    </div>
                    <div class="row like-button mx-0">
                        <div
                            class="action-group d-flex align-items-center my-25"
                        >
                            <b-link
                                class="btn btn-icon text-primary rounded-circle p-50 small"
                                @click="addReply(index)"
                            >
                                Reply
                            </b-link>
                        </div>
                    </div>
                </div>

                <!-- <div
                    class="option-reply d-flex align-items-center justify-content-between w-100 flex-row-reverse pl-3"
                >
                    
                    <div class="row like-button mx-0 pr-1">
                    
                        <div
                            class="action-group d-flex align-items-center my-25"
                        >
                            <b-link
                                class="btn btn-icon text-primary rounded-circle p-50 small"
                                @click="addReply(index)"
                            >
                                Reply
                            </b-link>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- Input box for replying -->
            <div
                class="w-100 cmt-posts d-flex align-items-end justify-content-center"
                v-if="replyIndex === index"
            >
                <div
                    class="border w-100 p-751 d-flex mb-0 flex-row align-items-end justify-content-around commentBox round mr-75"
                >
                    <CommentBox
                        placeholder="Enter Your Reply"
                        className="w-100 comment-box"
                        :id="'comment-field-' + comment.hashid"
                        :toolbar="false"
                    />
                </div>
                <b-button
                    variant="primary"
                    @click="addComments(comment)"
                    pill
                    class="blog-btn-block rounded-circle p-75 search-post mb-0"
                    :disabled="commentSubmitClicked"
                >
                    <b-img
                        :src="
                            require('@/assets/images/icons/post-cmt-white.svg')
                        "
                        width="22"
                    ></b-img>
                </b-button>
                <div class="replyto-cmd pb-1 my-1">
                    <b-link
                        class="text-primary btn btn-icon rounded-circle p-50"
                        @click="closeReplyBox"
                    >
                        <feather-icon
                            icon="XIcon"
                            size="18"
                            v-b-tooltip.hover.v-default
                            title="Cancel"
                        />
                    </b-link>
                </div>
            </div>
            <!-- Nested comments -->
            <NestedComments
                v-if="
                    openReplyIndex === index &&
                    comment.replies &&
                    comment.replies.length > 0
                "
                :comments="comment.replies"
                :postData="postData"
            />
        </div>
        <b-modal
            id="modal-login"
            cancel-variant="outline-secondary"
            centered
            title="Username"
            v-model="showUsernameModal"
            hide-footer
        >
            <b-form>
                <b-form-group>
                    <label for="username">Username:</label>
                    <b-form-input
                        id="username"
                        type="text"
                        placeholder="Enter username"
                        v-model="userName"
                    ></b-form-input>
                </b-form-group>
            </b-form>

            <div class="d-flex justify-content-end">
                <!-- Your custom buttons here -->
                <b-button
                    class="mr-2"
                    variant="outline-secondary"
                    @click="showUsernameModal = false"
                    >Cancel</b-button
                >
                <b-button variant="primary" @click="submitUsername"
                    >Continue</b-button
                >
            </div>
        </b-modal>
    </div>
</template>

<script>
import { BAvatar, BLink, BButton, BImg, VBTooltip } from "bootstrap-vue";
import CommentBox from "@/components/CommentBox.vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
    components: {
        BAvatar,
        BLink,
        BButton,
        BImg,
        NestedComments: () => import("./NestedComments.vue"),
        CommentBox,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    props: {
        comments: {
            type: Array,
            required: true,
        },
        postData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            localComments: [],
            comment_id: null,
            replyIndex: null,
            index: 0,
            commentSubmitClicked: false,
            showUsernameModal: false,
            userName: "",
            openReplyIndex: null,
        };
    },

    setup() {
        const USER_APP_STORE_MODULE_NAME = "app-tickets";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
            // store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME))
                    store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });

        const { refFormObserver, getValidationState, resetForm } =
            formValidation();

        return {
            refFormObserver,
            getValidationState,
            resetForm,
        };
    },

    mounted() {
        this.localComments = [...this.comments];
    },

    methods: {
        addReply(index) {
            this.replyIndex = index;
        },
        viewReply(index) {
            if (this.openReplyIndex === index) {
                this.openReplyIndex = null; // Close the replies if they are already open
            } else {
                this.openReplyIndex = index; // Open the replies of the clicked comment
            }
        },
        submitUsername() {
            localStorage.setItem("username", this.userName);
            this.showUsernameModal = false;
        },
        closeReplyBox() {
            this.replyIndex = null;
        },
        getComments() {
            axios
                .get(`/comments/get_public_comments/${this.postData.hashid}`)
                .then((res) => {
                    this.localComments = res.data;
                })
                .catch((err) => {
                    if (err.response && err.response.status && err.response.status == 404) {
                        this.showErrorImage = true;
                    }
                });
        },
        addComments(data) {
            const self = this;

            const comment = document.getElementById(
                `comment-field-${data.hashid}`
            ).value;

            if (!comment) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Comment Adding Failed",
                        icon: "BellIcon",
                        text: "Can't post empty comment",
                        variant: "danger",
                    },
                });
                return false;
            }

            if (localStorage.getItem("username") == null) {
                this.showUsernameModal = true;

                return;
            } else {
                const payload = {
                    username: localStorage.getItem("username"),
                    comment: comment,
                    comment_id: data.hashid,
                };

                axios
                    .post(
                        `/comments/${this.postData.hashid}/public_comments`,
                        payload
                    )
                    .then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Comment Added Successfully",
                                icon: "BellIcon",
                                text: res.data.message,
                                variant: "success",
                            },
                        });
                        this.replyIndex = null;
                        console.log("Response after adding comment:", res.data);
                        // Fetch all comments after adding a new comment or reply
                        this.getComments();

                        this.$emit("refresh-comments");
                    })
                    .catch((err) => {
                        const errorMessage =
                            err.response?.data?.error || err.toString();
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "BellIcon",
                                text: errorMessage,
                                variant: "danger",
                            },
                        });
                    });
            }
        },
    },
};
</script>
